import React from 'react'
import classnames from 'classnames'
import SEO from 'components/seo'
import Layout from 'layouts/zh'
import Banner from 'components/About/Banner'
import Nav from 'components/About/Nav'
import Card from 'components/About/Card'
import SwitchPicture, { SwitchPicturePosition } from 'components/About/SwitchPicture'
import SectionContent from 'components/SectionContent'
import PageBottomCard from 'components/PageBottomCard'

import 'styles/base.less'
import * as styles from './index.module.less'

const AboutUs: React.FC = (props) => {
  return (
    <Layout className={styles.AboutUs}>
      <AboutUsState demoUrl="" paradeUrl="" />
    </Layout>
  )
}

interface AboutUsStateProps {
  className?: string
  demoUrl?: string
  paradeUrl?: string
}
export const AboutUsState: React.FC<AboutUsStateProps> = (props) => {
  const { demoUrl, paradeUrl } = props

  const picList = [
    { img: require('assets/images/about/o1.png').default, name: SwitchPicturePosition.Left },
    { img: require('assets/images/about/o2.png').default, name: SwitchPicturePosition.Center },
    { img: require('assets/images/about/o3.png').default, name: SwitchPicturePosition.Right },
  ]

  return (
    <>
      <SEO
        title="神策數據 | 公司介紹  | 大數據用戶行為分析產品"
        description="神策數據（Sensors Data）成立以來，將數據分析服務、行業解決方案帶入企業，用數據分析實現業績增長，現已成為中國優秀的大數據分析服務公司"
        keywords="神策數據介紹,神策聯繫方式,神策公司,神策創始團隊,Sensorsdata"
      />
      <main className={styles.AboutUs}>
        <Banner
          title="神策數據：給客戶帶來價值"
          desc="公司在飛速發展，服務的客戶與日俱增，但無論走得多遠，我們都將本著給客戶帶來價值的價值觀，服務好每一個客戶，與您一同乘帆遠航。"
        />
        <Nav
          list={[
            {
              name: '公司介紹',
              url: '/about/aboutus.html',
              isActive: true,
            },
            {
              name: '加入我們',
              url: '/about/joinus.html',
              isActive: false,
            },
          ]}
        />
        <SectionContent title="神策數據 | Sensors Data">
          <div className={styles.Content}>
            <p>
              神策數據（Sensors
              Data），是專業的大數據分析和行銷科技服務提供商，為企業提供神策行銷雲、神策分析雲、神策數據根基平臺三大產品方案，通過全渠道的數據採集與全域用戶
              ID 打通，全場景多維度數據分析，全通道的精準用戶觸達，幫助企業實現數位化經營。
            </p>
            <p>
              神策數據立足大數據及用戶行為分析的技術與實踐前沿，提出基於數據流的企業運營框架——SDAF，即Sense（感知）、Decision（決策）、Action（行動）、Feedback（反饋）的數據閉環，並致力為客戶打造基於
              SDAF
              運營框架的數據閉環。業務現已覆蓋以網際網路、品牌零售、金融、融合媒體、企業服務、高科技、汽車、網際網路+
              等為代表的 30 多個主要行業，並可支持企業多個職能部門，目前已服務付費客戶 2000
              餘家。同時，公司擁有專業的服務團隊，為客戶提供與行銷和大數據相關的咨詢、解決方案和專業服務。
            </p>
          </div>
        </SectionContent>
        <SectionContent className={styles.Culture} title="企業文化" black>
          <ul className={styles.CultureList}>
            <li>
              <p className={classnames(styles.Label, styles.Label_1)}>我們的願景</p>
              <p>建構互聯網數據根基</p>
            </li>
            <li>
              <p className={classnames(styles.Label, styles.Label_2)}>我們的使命</p>
              <p>幫助客戶實現數據驅動</p>
            </li>
            <li>
              <p className={classnames(styles.Label, styles.Label_3)}>我們的價值觀</p>
              <p>給客戶帶來價值</p>
            </li>
          </ul>
          <SwitchPicture picList={picList} className={styles.SwitchPicture} />
        </SectionContent>
        <SectionContent className={styles.ContactUs} title="聯絡我們">
          <ul>
            <li>
              <div className={styles.ContactUsIcon}>
                <img src={require('assets/images/about/qrcode-01.png').default} alt="" />
                <img src={require('assets/images/about/qrcode-02.png').default} alt="" />
              </div>
              <h3>中國台北</h3>
              <p>中國台灣省台北市中山區南京東路3段168號14樓</p>
            </li>
          </ul>
          <div className={styles.CardGroup}>
            <Card className={styles.CardItem} icon={require('assets/images/about/mobile.svg').default}>
              <h3>Tel</h3>
              <p>02-2731-5908</p>
            </Card>
            <Card
              className={classnames(styles.CardItem, styles.CardItemEmail)}
              icon={require('assets/images/about/email.svg').default}
            >
              <h3>E-mail</h3>
              <p>contactus@sensorsdata.com</p>
            </Card>
          </div>
        </SectionContent>
        <PageBottomCard
          title="打造以用户为中心的智慧媒体大数据策略平台！"
          desc="立即预约，快速找到适合您的智能大屏解决方案！"
          leftButtonText="体验智能大屏 Demo"
          leftButtonHref={demoUrl}
          rightButtonText="预约演示"
          rightButtonHref={paradeUrl}
        />
      </main>
    </>
  )
}

export default AboutUs
