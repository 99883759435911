import React from 'react';
import classnames from 'classnames'

import * as styles from "./index.module.less";

interface CardProps {
  className?: string;
  icon?: string;
}
const Card: React.FC<CardProps> = props => {
  const {
    className,
    children,
    icon
  } = props;

  return (
    <div className={classnames(styles.Card, className)}>
      <div className={styles.CardLeft}>
        <img src={icon} alt="" />
      </div>
      <div className={styles.CardCenter}>{children}</div>
      {/* <div className={styles.CardRight}></div> */}
    </div>
  );
}

export default Card;