import React from 'react';
import classnames from 'classnames'
import Link from "components/Base/Link";

import * as styles from "./index.module.less";

export interface AboutNavItem {
  name: string;
  url: string;
  isActive?: boolean;
}
interface AboutNavProps {
  className?: string;
  list?: AboutNavItem[]
}
const AboutNav: React.FC<AboutNavProps> = props => {
  const {
    list
  } = props;

  return (
    <div className={styles.AboutNav}>
      {list?.map(item => <Link 
        key={item.name} 
        className={classnames(styles.NavItem, {[styles.Active]: item.isActive})} 
        to={item.url}>{item.name}</Link>)}
    </div>
  );
}

export default AboutNav;