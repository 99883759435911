import React from 'react'; 

import * as styles from "./index.module.less";

interface AboutBannerProps {
  className?: string;
  title?: string;
  desc?: string;
}
const AboutBanner: React.FC<AboutBannerProps> = props => {
  const {
    title,
    desc
  } = props;
  return (
    <div className={styles.AboutBanner}>
      <h1>{title}</h1>
      <p>{desc}</p>
    </div>
  );
}



export default AboutBanner;