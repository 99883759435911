import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import * as styles from './index.module.less';

export enum SwitchPicturePosition {
    Left = styles.Left,
    Right = styles.Right,
    Center = styles.Center
}
interface SwitchPictureProps {
    className?: string;
    picList?: any;
}
const SwitchPicture: React.FC<SwitchPictureProps> = props => {
    const {
        className,
        picList
    } = props;
    const [curpicList, setPicList] = useState(picList);

    useEffect(() => {
        setPicList(picList);
    });

    function changeName(item: any): void {
        const curName = item.name;
        if (curName === SwitchPicturePosition.Center) {
            return;
        }

        const arr = curpicList;

        arr.forEach((el: any) => {
            if (el.name === SwitchPicturePosition.Center) {
                el.name = curName;
            } else if (el.name === curName) {
                el.name = SwitchPicturePosition.Center;
            }
        });
        setPicList([...arr]);
    }

    return (
        <div className={classnames(styles.SwitchPicture, className)}>
            <ul>
                {(curpicList || []).map((el: any, idx: number) => {
                    return (
                        <li
                            key={idx}
                            className={el.name}
                            onClick={() => {
                                changeName(el);
                            }}
                        >
                            <img src={el.img} alt="" />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default SwitchPicture;
